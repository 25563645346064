<script lang="ts" setup>
import { ref, computed } from "vue";
import ProjectLinksGrid from "@/components/iam/ProjectLinksGrid.vue";

const props = defineProps({
  projectId: undefined,
});

const addLink = ref({ name: "IAM-ProjectLinkAdd", params: { projectLinkId: "-", addModus: true } });

const preFilter = computed(() => []);
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <project-links-grid :projectId="props.projectId" :preFilter="preFilter" :addLink="addLink"></project-links-grid>
  </v-container>
</template>
