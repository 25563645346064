<script lang="ts" setup>
import { computed, onMounted, getCurrentInstance } from "vue";
import { getProjectLinks } from "@/services/api/iam.api";
import GridPagination from "@/components/general/GridPagination.vue";
import useGrid27 from "@/composables/grid27.js";

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  projectId: undefined,
  preFilter: {
    type: Array,
    default: () => [],
  },
  viaAccess: undefined,
  addLink: undefined,
});

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Type", value: "sourceDescription" },
  { text: "Referentie", value: "sourceReference" },
  { text: "Omschrijving", value: "description" },
];
const headers = computed(() => _headers);

onMounted(() => {
  fetchFirstPage();
});

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  const response = await getProjectLinks(props.projectId, pageNumber0, pageSize, sortBy, sortDesc);
  console.log("projectlinkgrid response ", response);
  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-filters align-items-start">
      <v-spacer></v-spacer>
      <v-col cols="auto" class="text-right">
        <v-btn v-if="props.addLink" dark class="primary mt-4" :to="props.addLink">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'IAM-ProjectLinkDetails', params: { projectLinkId: item.id } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-layout wrap>
          <v-checkbox class="ma-0 pa-0" disabled v-model="item.active" hide-details></v-checkbox>
        </v-layout>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
